import React from "react";

import Privacy from "../components/sharedComponents/privacy";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

// markup
const privacypolicypage = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Terms & Condition" />
      <Layout isSticky>
        <Privacy />
      </Layout>
    </div>
  );
};

export default privacypolicypage;
